import styles from './index.module.css';
import { useAppContext } from '../../hooks/useAppContext';
import { useCallback } from 'react';

const Overlay = props => {
    const { className } = props;
    const { overlay, dispatch } = useAppContext();
    const {active} = overlay;
    const rootClasses = `${styles.root} ${className || ''} ${!active ? 'hidden' : ''}`;
    const clickHandler = useCallback(() => {
        dispatch({ payload: {active: false}, type: 'CHANGE_OVERLAY' });
        dispatch({ payload: {active: false}, type: 'CHANGE_POPUP' });
    });

    return (
        <div className={rootClasses} onClick={clickHandler}/>
    );
};

export default Overlay;
