import { useEffect, useState } from 'react';
import { useApiData } from "../../useApiData";
import { useAppContext } from '../../useAppContext';

const FETCH_INTERVAL = 5000;

export const useResults = (props) => {
  const { mode, domain, report } = props;
  const { dispatch } = useAppContext();
  const [ summary, setSummary ] = useState(null);
  const [ result, setResult ] = useState(null);
  const [ startScanning, setStartScanning ] = useState(false);
  const [ error, setError ] = useState(null);

  const {
    data: resultsData,
    getData: getResults,
    error: fetchError
  } = useApiData();

  const {
    data: errorsData,
    getData: getErrorsData,
  } = useApiData();

  const {
    data: scanData,
    getData: getScan,
    error: scanError
  } = useApiData();

  useEffect(() => {
    if (mode === 'results' || mode === 'results_with_scan') {
      if (report) {
        getResults(report);
      } else {
        getScan(`/api/sites/${domain.id}/run`);
      }
    }
  }, [report]);

  // Start Scan
  useEffect(() => {
    if (mode === 'scan') {
      setStartScanning(true);
      setResult(null);
      getScan(`/api/sites/${domain.id}/run`);
    }
  }, [mode]);

  // Callback after scan is being proceed
  useEffect(() => {
    if (mode === 'scan' && scanData) {
      getResults(`/api/reports/${scanData.report}`);
    }
  }, [mode, scanData]);

  // Get table data if scan is finished
  useEffect(() => {
    if (resultsData?.status === 'finished') {
      getErrorsData(`/api/reports/${resultsData?.id}/seo_errors`);
    }
  }, [resultsData]);

  // Watch for scan results
  useEffect(() => {
    if (!resultsData || resultsData.status !== 'pending') {
      return;
    }

    let interval = setInterval(() => {
      getResults(`/api/reports/${resultsData.id}`);
    }, FETCH_INTERVAL);

    return () => clearInterval(interval);
  }, [resultsData]);


  // Set Scan summary data
  useEffect(() => {
    if (resultsData) {
      setSummary(resultsData);
    }

    if (fetchError) {
      setError(fetchError);
    }

    if (errorsData && resultsData?.status === 'finished') {
      setResult(errorsData);
    }

  }, [resultsData, fetchError, errorsData]);

  // Update Plan Data after Scanning
  useEffect(() => {
    if (resultsData) {
      dispatch({ updatePlan: true, type: 'UPDATE_PLAN_DATA' });
    }

  }, [resultsData]);

  return { summary, result, startScanning, error, scanError }
}