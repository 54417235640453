import { createContext, useReducer, useEffect } from 'react'
import { useApiData } from '../hooks/useApiData';

export const AppContext = createContext();

export const AppReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_PLAN_DATA':
      return { ...state, updatePlan: action.updatePlan }
    case 'LOGIN':
      return { ...state, user: action.payload, plan: action.plan }
    case 'CHANGE_OVERLAY':
      return { ...state, overlay: action.payload }
    case 'CHANGE_POPUP':
      return { ...state, popup: action.payload }
    case 'LOGOUT':
      return { ...state, user: null, plan: null }
    case 'AUTH_IS_READY':
      return { ...state, user: action.payload, plan: action.plan, authIsReady: true }
    default:
      return state
  }
}

export const AppContextProvider = ({ children }) => {
  const { data: userData, getData: getUser } = useApiData();
  const { data: planData, getData: getPlan } = useApiData();
  const links = {
    login: process.env.REACT_APP_LOGIN_URL
  }

  const [state, dispatch] = useReducer(AppReducer, {
    user: null,
    authIsReady: false,
    plan: null,
    updatePlan: false,
    Links: links,
    overlay: {
      active: false
    },
    popup: {
      active: false,
      titleData: null,
      contentData: null,
      footerData: null
    }
  });

  useEffect(() => {
    getUser('/api/users/me');
  }, []);

  useEffect(() => {
    getPlan('/api/users/billing');
    state.updatePlan = false;
  }, [state.updatePlan]);

  useEffect(() => {
    if (userData && planData) {
      dispatch({ type: 'AUTH_IS_READY', payload: userData, plan: planData });
    }
  }, [userData, planData]);

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      { children }
    </AppContext.Provider>
  )
}