import styles from "./index.module.css"
import React from "react"

export const IconButton = props => {
  const { className, clickHandler, disabled, children } = props
  const classes = `${styles.root} ${className}`

  return (
      <button
          className={classes}
          onClick={clickHandler}
          disabled={disabled}
      >
{children}
    </button>
  )
}

export default IconButton
