import { NavLink } from 'react-router-dom';
import { generateId } from '../../utils/constants';

import styles from './sidebar.module.css';

const SidebarNav = () => {
  const classes = styles;
  const label = '(coming soon)';

  const navList = [
    {
      id: generateId(),
      title: 'Audit',
      content: [
        {
          id: generateId(),
          url: '/audit/by-sitemap',
          title: 'Start audit via sitemap',
          disabled: false
        },
        {
          id: generateId(),
          url: '/audit/by-cron',
          title: 'Start audit with cron',
          disabled: true
        },
        {
          id: generateId(),
          url: '/audit/scan-log',
          title: 'Scan log',
          disabled: true
        },
        {
          id: generateId(),
          url: '/audit/op-seo-check',
          title: 'Start One Page SEO Check',
          disabled: true
        }
      ]
    },
    {
      id: generateId(),
      title: 'Account',
      content: [
        {
          id: generateId(),
          url: '/account/domain',
          title: 'Domain',
          disabled: true
        },
        {
          id: generateId(),
          url: '/account/notification',
          title: 'Notification',
          disabled: true
        },
        {
          id: generateId(),
          url: '/account/settings',
          title: 'Settings',
          disabled: true
        }
      ]
    }
  ];

  const linksList = [
    {
      id: generateId(),
      url: 'https://support.amasty.com/portal/en/newticket?departmentId=34453000000007061&layoutId=34453000000023011',
      title: 'Feedback',
      disabled: false
    },
    {
      id: generateId(),
      url: 'https://amasty.com/docs/doku.php?id=magento_2:seo_toolkit',
      title: 'SEO FAQ',
      disabled: false
    }
  ];

  return (
    <nav className={classes.navigation}>
      <ul className={classes.links}>
        {navList.map(item => (
          <li className={`sidebarItem ${classes.item} ${classes.level0}`} key={item.id}>
            <span className={classes.title}>
              {item.title}
            </span>
            <ul>
              {item.content && item.content.map(item => (
                <li className={classes.item} key={item.id}>
                  <NavLink to={item.url}>
                    <span className={classes.title}>{item.title}</span>
                    {item.disabled && <span className={classes.label}>{label}</span>}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>
        ))}

        {<li className={`sidebarItem ${classes.item} ${classes.level0}`}>
          <span className={classes.title}>
            Support
          </span>
          <ul>
            {linksList && linksList.map(item => (
              <li className={classes.item} key={item.id}>
                <a href={item.url} target="_blank" rel="noreferrer">
                  <span className={classes.title}>{item.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </li>}
      </ul>
    </nav>
  )
}

export default SidebarNav
