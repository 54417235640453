import styles from './scanTable.module.css';
import Titles from './titles';
import List from './list';
import {useEffect, useState} from "react";
import Details from "./details";
import {useApiData} from "../../hooks/useApiData";

const ScanTable = (props) => {
  const { summary, result } = props;
  const classes = styles;

  const [ details, setDetails ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ element, setElement ] = useState(null);

  const {
    data: urlData,
    getData: getUrlData,
    dataLoading
  } = useApiData();

  const handleSeeDetails = (element, page = 1) => {
    getUrlData(`/api/report_items?page=${page}&report=${summary.id}&errors=${element.seo_error_id}`);
    setLoading(true);
    setElement(element);
  }

  useEffect(() => {
    if (urlData) {
      setDetails({
        element: element,
        urlData: urlData
      });
    }

    setLoading(dataLoading)
  }, [urlData, dataLoading]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.inner}>
        {loading && <div className="loader-mask"/>}

        {!details && (
          <div className={classes.table}>
            <Titles />
            <List data={result} handleSeeDetails={handleSeeDetails} />
          </div>
        )}

        {details && (
          <Details
            summary={summary}
            details={details}
            setDetails={setDetails}
            handleSeeDetails={handleSeeDetails}
          />
        )}
      </div>
    </div>
  )
}

export default ScanTable
