import styles from './HelpPage.module.css';
import {ReactComponent as BackIcon} from "../../assets/icons/arrow-back.svg";
import {NavLink} from "react-router-dom";

const HelpPage = () => {
  return (
    <div className={`${styles.wrapper} page-block`}>

      <h2 className={styles.title}>
        <NavLink className={`${styles.back} btn-clear`} to="/audit/by-sitemap">
          <BackIcon />
          <span>Go back</span>
        </NavLink>
        <span>How to verify your site</span>
      </h2>
      <p>To confirm domain ownership, the owner/administrator of Magento site should:</p>
      <ol className={styles.firstLevel}>
        <li>
          <span>Get a confirmation code in the Customer Account of SEO Health Checker. The code looks like one of the following examples:</span>
          <ol type="a">
            <li>HTML: <code>&lt;!--Qu1ZDHy3fBovodk8LLJJpLuMGXEO2PV9--&gt;</code></li>
            <li>META TAG: <code>&lt;meta name=&quot;p:domain_verify&quot; content=&quot;Qu1ZDHy3fBovodk8LLJJpLuMGXEO2PV9&quot;/&gt;</code></li>
          </ol>
        </li>

        <li>
          <span>Paste this code into your Magento admin panel.</span>
          <ol type="a">
            <li>Go to Design → Configuration.</li>
            <li>Select the required website.</li>
            <li>Paste the code into HTML Head → Scripts and Style Sheets.</li>
            <li>Save and clean cache.</li>
          </ol>
        </li>
      </ol>
    </div>
  )
}

export default HelpPage
