import styles from "./index.module.css"
import React from "react"

export const PrimaryButton = props => {
  const { title, className, onMouseOver, clickHandler, colorScheme, disabled } = props
  const scheme = colorScheme ? colorScheme.toLowerCase() : ""
  const classes = `${styles.root} ${styles[scheme]} ${className}`
  const titleNode = title && <span>{title}</span>

  return (
    <button
      className={classes}
      onClick={clickHandler}
      disabled={disabled}
      onMouseEnter={onMouseOver || null}
    >
      {titleNode}
    </button>
  )
}

export default PrimaryButton
