import styles from './scanSummary.module.css';

const List = (props) => {
  const classes = styles;
  const { data } = props;
  const valuePlaceholder = '-';

  return (
    <ul>
      {data && data.map( element => (
        <li className={classes.item} key={element.id}>
          {element.icon && (
            <i className={classes.icon}>
              {element.icon}
            </i>
          )}
          <span className={classes.title}>
            {element.title}
          </span>
          <span className={classes.value}>
            {element.value || valuePlaceholder}
          </span>
        </li>
      ))}
    </ul>
  )
}

export default List
