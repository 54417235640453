import styles from './scanSummary.module.css';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as NotificationIcon } from '../../assets/icons/notification.svg';

import { ReactComponent as ScanLoader } from '../../assets/loaders/scan-loader.svg';

import List from './list';
import { formatDate } from '../../utils/formatDate';
import CircleProgressBar from '../CircleProgressBar';
import { Fragment } from 'react';

const ScanSummary = (props) => {
    const classes = styles;
    const { data } = props;
    const { message } = data;

    const summaryScore = Math.round(data?.score) || 0;

    const domainInfo = [
        {
            id: 1,
            title: 'Scanned URLs',
            value: data?.scannedUrls || null,
            icon: false
        },
        {
            id: 2,
            title: 'Created at',
            value: formatDate(data?.createdAt),
            icon: false
        },
        {
            id: 3,
            title: 'Updated at',
            value: formatDate(data?.updatedAt),
            icon: false
        }
    ];

    const domainResults = [
        {
            id: 4,
            title: 'Critical errors',
            value: data?.criticalErrors || null,
            icon: <ErrorIcon/>
        },
        {
            id: 5,
            title: 'Warnings',
            value: data?.warnings || null,
            icon: <WarningIcon/>
        },
        {
            id: 6,
            title: 'Notification',
            value: data?.notices || null,
            icon: <NotificationIcon/>
        }
    ];

    const scoreBLock = data?.status !== 'finished' ? (
        <div className={classes.preloaderWrap}>
      <ScanLoader/>
            {(data?.processCountPending && <span>{data?.processCountPending} left</span>) || null}
    </div>
    ) : data?.status === 'finished' ? (
        <span className={classes.score}>
      <span>{summaryScore}</span>
      <span className={classes.of}>of 100</span>
    </span>
    ) : null;

    return (
        <Fragment>
            <div className={classes.summary}>
      <div className={classes.scoreBlock}>
        <CircleProgressBar className={classes.inner} progress={summaryScore / 100}>
          {scoreBLock}
        </CircleProgressBar>
      </div>

      <div className={classes.detailsBlock}>
        <List data={domainInfo}/>
        <List data={domainResults}/>
      </div>
    </div>
            {message &&
                <span className={styles.message}>{message}</span>
            }
      </Fragment>
    );
};

export default ScanSummary;
