import { useAppContext } from './useAppContext';
import { useEffect, useState } from 'react';

export const usePlanInfoBar = props => {
    const { user, plan } = useAppContext();
    const [data, setData] = useState([]);
    const excludedPlanData = ['stream_qty'];
    const { billingServiceParameters } = plan;
    const { saasServices: services } = user;
    const planData = services[Object.keys(services)[0]].subscription;
    const { title, paymentMethod } = planData;

    const filteredData = billingServiceParameters.filter((item) => {
        return !excludedPlanData.includes(item.key) && (item.value || item.used || item.unlimited);
    });

    filteredData.forEach(item => {
        if (item.key === 'domain_active') {
            item.title = 'Websites';
            item.order = 0;
        }

        if (item.key === 'partition_domain_scan_limit') {
            item.title = 'Pages';
            item.order = 1;
        }

        if (item.unlimited) {
            item.used = 0;
            item.valueTitle = 'Unlimited';
        } else {
            item.valueTitle = `${item.used}/${item.value}`;
        }
    });

    useEffect(() => {
        setData(filteredData);
    }, [plan]);

    return {
        title: title,
        plan: data,
        paymentMethod
    };
};
