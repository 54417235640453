import { ReactComponent as PlatesIcon } from '../../assets/icons/plates.svg';

import styles from './ComingSoon.module.css';

const ComingSoon = () => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Service is still in development</h2>
      <PlatesIcon />
    </div>
  )
}

export default ComingSoon
