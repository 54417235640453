import styles from './scanTable.module.css';

const List = props => {
  const classes = styles;
  const { data, handleSeeDetails } = props;
  const valuePlaceholder = '-';

  return (
    <ul>
      {data.result && data.result.map(element => (
          <li
            className={`${classes[element.errorType]} ${classes.row} ${classes.item}`}
            key={element.seo_error_id}
          >
            <span className={classes.firstCell}>
              <i className={classes.icon}/>
            </span>

            <span>{element.title || valuePlaceholder}</span>

            <span>{element.quantity || valuePlaceholder}</span>

            <span className={classes.lastCell}>
              <button
                className={`${classes.link} btn-clear`}
                onClick={() => handleSeeDetails(element)}
              >
                  See URL
                </button>
            </span>
          </li>
        ))}
    </ul>
  );
};

export default List;
