import { useState } from 'react';
import { OPTIONS } from '../utils/constants';

export const useApiData = () => {
    const [data, setData] = useState(null);
    const [postResponse, setPostResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);

    const getData = (url) => {
        if (!url) {
            return;
        }

        setLoading(true);

        fetch(`https://${OPTIONS.backend}${url}`, {
            redirect: 'manual',
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            referrerPolicy: 'strict-origin-when-cross-origin'
        }).then(async (response) => {
            const isJson = response.headers.get('content-type')?.includes('application/json');
            const data = isJson ? await response.json() : null;

            if (!response.ok) {
                const error = (data && data.message) || response.status;

                return Promise.reject({ response, error });
            }

            if (response.ok) {
                setLoading(false);
                setData(data);
            }
        }).catch((response, error) => {
            const redirectLink = OPTIONS.sso_url;

            setLoading(false);

            if (response.error === 400) {
                setError(response?.data?.result || response.error);
            } else {
                setError(error || response.error);
            }

            if (response.error === 0 || error === 0) {
                window.location.replace(redirectLink);
            }
        });
    };

    const postData = (url = '', data = {}) => {
        if (!url) {
            return;
        }

        fetch(`https://${OPTIONS.backend}${url}`, {
            redirect: 'manual',
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            referrerPolicy: 'strict-origin-when-cross-origin',
            body: JSON.stringify(data)
        }).then(async (response) => {
            const data = await response.json();

            if (!response.ok) {
                const error = (data && data.message) || response.status;

                if (response.status === 422) {
                    setError(false);
                    setError(data.detail || error);
                }

                return Promise.reject(error);
            }

            if (response.ok) {
                setPostResponse(data);
            }
        });
    };

    return { data, getData, postResponse, postData, error, loading };
};
