import Logo from '../Logo';
import PlanInfoBar from '../PlanInfoBar';
import { useHeader } from '../../hooks/useHeader';
import Button from '../Button';

import styles from './index.module.css';

const Header = props => {
    const {buttonHandler} = useHeader();

    return (
        <header className={styles.root}>
            <Logo className={styles.logo}/>
            <div className={styles.container}>
                <PlanInfoBar className={styles.planInfoBar}/>
                <Button className={styles.button}
                        onClick={buttonHandler}
                        colorScheme={'white'}
                        viewType={'primary'}
                        title={'Manage plan'}/>
            </div>
        </header>
    );
};

export default Header;
