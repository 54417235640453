import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import styles from './index.module.css';
import { usePopup } from '../../hooks/usePopup';
import Button from '../Button';

const Popup = props => {
    const {className} = props;
    const {active, close: closeHandler, contentData, footerData, titleData} = usePopup();
    const rootClasses = `${styles.root} ${className || ''} ${!active ? 'hidden' : ''}`;

    const title = titleData ? (
        <header className={styles.header}>{titleData}</header>
    ) : null;

    const content = contentData ? (
        <main className={styles.content}>{contentData}</main>
    ) : null

    const footer = footerData ? (
        <footer className={styles.footer}>{footerData}</footer>
    ) : null;

    return (
        <section className={rootClasses}>
            <Button className={styles.closeButton} viewType={'icon'} onClick={closeHandler}>
                <CloseIcon/>
            </Button>
            {title}
            {content}
            {footer}
        </section>
    );
};

export default Popup;
