import styles from './scanTable.module.css';

const Titles = () => {
  const classes = styles;
  const titles = [
    {
      id: 1,
      value: 'Priority'
    },
    {
      id: 2,
      value: 'Error type'
    },
    {
      id: 4,
      value: 'Total number of errors'
    }
  ];

  return (
    <ul className={classes.titles}>
      {titles && titles.map(title => (
        <li className={classes.title} key={title.id}>
          <span>{title.value}</span>
        </li>
      ))}
    </ul>
  );
};

export default Titles;
