import { Link } from 'react-router-dom';
import InputField from '../../../components/InputField';
import Results from './results';

import { useDomains } from '../../../hooks/audit/bySitemap/useDomains';
import styles from './index.module.css';

export default function BySitemap() {
    const classes = styles;
    const {
        domains,
        mode,
        domain, setDomain,
        error,
        handleResults,
        runScan,
        handleScan,
        fetchDomainsData,
        handleVerify,
        handleSaveDomain,
        loading
    } = useDomains();

    const list = (domains && domains.length) && (
        <div className={classes.listWrap}>
          <h3>Domains list:</h3>
          <ul className={classes.list}>
            {domains && domains.map(element => (
                <li key={element.id}>
                <div className={classes.content}>
                  <span>{element.url}</span>
                    {(!element.isVerify && element.verifyCode) && (
                        <label className={classes.code}>
                      <input type="text" readOnly value={element.verifyCode}/>
                    </label>
                    )}
                </div>
                <div className={classes.actions}>
                  {element.isVerify && (
                      <>
                      <button onClick={() => handleScan(element)} className="btn">
                        start scan
                      </button>
                          {element.reports.length && (
                              <button onClick={() => handleResults(element.id)} className="btn">view results</button>
                          ) || null}
                    </>
                  ) || (
                      <button onClick={() => handleVerify(element.id)} className="btn">verify</button>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
    ) || null;

    return (
        <>
              {!domain && (
                  <div className="page-block">
                  {loading && <div className="loader-mask"/>}
                      <InputField error={error} callback={handleSaveDomain} buttonTitle="add domain"
                                  placeholder="Example: https://www.amasty.com"/>
                      {error && <div className="error">{error}</div>}
                      <Link className={classes.link} to={'/how-to'}>
                          How to verify your site
                      </Link>
                      {list}
                </div>
              )}

            {domain && <Results domain={domain} setDomain={setDomain} fetchDomainsData={fetchDomainsData} mode={mode}
                                runScan={runScan}/>}
    </>
    );
}
