import { useCallback } from 'react';
import { useAppContext } from './useAppContext';

export const usePopup = props => {
    const { dispatch, popup } = useAppContext();
    const { active, contentData, footerData, titleData } = popup;

    const close = useCallback(() => {
        dispatch({ payload: { active: false }, type: 'CHANGE_OVERLAY' });
        dispatch({ payload: { active: false, titleData: null, contentData: null, footerData: null }, type: 'CHANGE_POPUP' });
    });

    const open = useCallback((data) => {
        const {title, content, footer} = data

        dispatch({ payload: { active: true }, type: 'CHANGE_OVERLAY' });
        dispatch({ payload: { active: true, titleData: title, contentData: content, footerData: footer }, type: 'CHANGE_POPUP' });
    });

    return {
        close,
        titleData,
        contentData,
        footerData,
        open,
        active
    };
};
