import { Fragment, useCallback } from 'react';
import Button from '../../../../components/Button';
import { useAppContext } from '../../../../hooks/useAppContext';
import { usePopup } from '../../../../hooks/usePopup';

export const Messages = () => {
    const { Links } = useAppContext();
    const { open: openPopup, close: closePopup } = usePopup();

    const upgradeClickHandler = useCallback(() => {
        closePopup();
        window.open(Links.login, '_blank');
    }, []);

    const domainLimit = () => {
        openPopup({
            title: <Fragment>
                        <b>You’ve reached your limit.</b>
                   </Fragment>,
            content: <Fragment><span>To scan more websites, upgrade your plan</span></Fragment>,
            footer: <Fragment>
                        <Button onClick={upgradeClickHandler} colorScheme={'blue'} viewType={'primary'}
                                title={'Upgrade'}/>
                    </Fragment>
        });
    };

    const sitePagesLimit = (limit, proceedHandle) => {
        openPopup({
            title: <Fragment>
                        <b>You’ve reached your limit.</b>
                   </Fragment>,
            content: <Fragment><span>You have <b>{limit} pages</b> available for scan.</span></Fragment>,
            footer: <Fragment>
                          {limit > 0 &&
                              <Button colorScheme={'transparent'}
                                      onClick={() => {
                                          proceedHandle();
                                          closePopup();
                                      }}
                                      viewType={'primary'}
                                      title={'Proceed'}/>
                          }
                        <Button onClick={upgradeClickHandler}
                                colorScheme={'blue'}
                                viewType={'primary'}
                                title={'Upgrade'}/>
                    </Fragment>
        });
    };

    return {
        domainLimit,
        sitePagesLimit
    };
};
