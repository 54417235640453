import SidebarFooter from "./footer";
import SidebarNav from './sidebarNav';

import styles from './sidebar.module.css'

const Sidebar = () => {
  const classes = styles;

  return (
    <aside className={classes.sidebar}>
      <div className={classes.sidebarContent}>
        <SidebarNav />
        <SidebarFooter />
      </div>
    </aside>
  )
}

export default Sidebar
