import { useEffect, useState } from 'react';
import { useApiData } from '../../useApiData';
import { useAppContext } from '../../useAppContext';
import { Messages } from '../../../components/Plan/Messages/Limits';

import { OPTIONS } from '../../../utils/constants';

const TIMEOUT = OPTIONS.ERROR_LIFETIME;

export const useDomains = () => {
    const pagesNotificationLimit = 100;
    const [domains, setDomains] = useState(null);
    const [domain, setDomain] = useState(false);
    const [mode, setMode] = useState('');
    const [scannedDomain, setScannedDomain] = useState(null);
    const [error, setError] = useState(false);
    const { domainLimit: domainLimitPopup, sitePagesLimit: sitePagesLimitPopup } = Messages();
    const { plan, dispatch } = useAppContext();
    const { billingServiceParameters } = plan;
    const planDomainData = billingServiceParameters.filter(item => item.key === 'domain_active').shift();
    const availableAddDomain = planDomainData.used < planDomainData.value || planDomainData.unlimited;

    const {
        data: domainsData,
        getData: getSites,
        postResponse,
        postData,
        error: postError,
        loading
    } = useApiData();

    const {
        data: domainData,
        getData: getSite
    } = useApiData();

    const { data: siteBillingData, getData: getSiteBillingData } = useApiData();

    // START Scanning domain pages

    const handleScan = (element) => {
        getSiteBillingData(`/api/sites/${element.id}/billing`);
        setScannedDomain(element);
    };

    useEffect(() => {
        if (!siteBillingData) {
            return false;
        }

        const { allowedValue, unlimited } = siteBillingData;
        const { id, reports } = scannedDomain;

        if (reports.length) {
            getSite(`/api/sites/${id}`);
            setMode('results_with_scan');

            return false;
        }

        if (!unlimited && allowedValue < pagesNotificationLimit) {
            sitePagesLimitPopup(allowedValue, () => runScan(id));

            return false;
        }

        runScan(id);
    }, [siteBillingData]);

    // END Scanning domain pages

    const handleVerify = (id) => {
        getSite(`/api/sites/${id}/verify`);
    };

    const runScan = (id) => {
        getSite(`/api/sites/${id}`);
        setMode('scan');
    };

    const handleResults = (id) => {
        getSite(`/api/sites/${id}`);
        setMode('results');
    };

    const handleSaveDomain = (domain) => {
        if (!availableAddDomain) {
            domainLimitPopup();

            return false;
        }

        postData('/api/sites', {
            url: domain
        });
    };

    const fetchDomainsData = () => {
        getSites('/api/sites');
    };

    useEffect(() => {
        fetchDomainsData();
    }, []);

    // Domains list
    useEffect(() => {
        if (domainsData) {
            setDomains(domainsData);
        }
    }, [domainsData]);

    // Domain item
    useEffect(() => {
        if (domainData) {
            setDomain(domainData);
        }
    }, [domainData]);

    // Set domain to the list of domains and update plan data
    useEffect(() => {
        if (postResponse) {
            setDomains((prevDomains) => {
                return [...prevDomains, postResponse];
            });

            dispatch({ updatePlan: true, type: 'UPDATE_PLAN_DATA' });
        }
    }, [postResponse]);

    // Set verify state to the domain
    useEffect(() => {
        if (domain && domains) {
            const newState = domains.map(element => {
                if (element.id === domain.id) {
                    return { ...element, isVerify: domain.isVerify };
                }

                return element;
            });

            setDomains(newState);
        }

        if (domain && !domain.isVerify) {
            setError(false);
            setError(`${domain.url} is not verified`);

            const timer = setTimeout(() => {
                setError(false);
            }, TIMEOUT);

            return () => clearTimeout(timer);
        }
    }, [domain]);

    // Listen for domain data fetch. render results page
    useEffect(() => {
        setDomain(null); // clear domain info object to prevent an old data render

        if (domainData) {
            setMode(mode);
            setDomain(domainData);
        }
    }, [domainData]);

    // Manage postError
    useEffect(() => {
        if (!postError) {
            return;
        }

        setError(false);
        setError(postError);

        const timer = setTimeout(() => {
            setError(false);
        }, TIMEOUT);

        return () => clearTimeout(timer);
    }, [postError]);

    return {
        error,
        setError,
        domains,
        setDomains,
        domain,
        setDomain,
        mode,
        setMode,
        fetchDomainsData,
        runScan,
        handleSaveDomain,
        handleScan,
        handleResults,
        handleVerify,
        domainData,
        loading,
        postError,
        postResponse
    };
};
