import styled from 'styled-components';

const CircleProgressBar = styled.div({
  background: ({ progress }) => {
    const angle = 360 * progress

    return `radial-gradient(white 60%, transparent 61%),
      conic-gradient(transparent 0deg ${angle}deg, #F2F2FC ${angle}deg 360deg),
      conic-gradient(#FF6770 0deg, #FFBD5F 90deg, #FCFF72 180deg, #B8FF72)`
  },
  borderRadius: '50%',
  width: ({ size }) => {
    return `${size || 150}px`
  },
  height: ({ size }) => {
    return `${size || 150}px`
  }
})

export default CircleProgressBar
