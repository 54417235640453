import { Fragment } from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import { useAppContext } from './hooks/useAppContext';

import './styles/App.css';

import Header from './components/Header';
import Sidebar from './components/Sidebar/index';
import BySitemap from './pages/audit/bySitemap/BySitemap';
import ComingSoon from './pages/comingSoon/ComingSoon';
import HelpPage from "./pages/help/HelpPage";
import Loader from "./components/Loader";
import Overlay from './components/Overlay';
import Popup from './components/Popup';

function App() {
  const { authIsReady, user } = useAppContext();

  return (
    <Fragment>
      {authIsReady && user && (
        <BrowserRouter>
          {!user.hasAccess && (
            <div className="page-container">
              <div className="error -no-subscription">
                Please, activate your subscription for <span>SEO Toolkit Pro</span> or <span>SEO Toolkit Premium</span>.
              </div>
            </div>
          )}
          {user.hasAccess && <Header />}
          {user.hasAccess && <Sidebar />}
          {user.hasAccess && <div className="page-container">
            <Switch>
              <Route exact path="/">
                <Redirect to="/audit/by-sitemap" />
              </Route>

              <Route exact path="/audit/by-sitemap">
                <BySitemap />
              </Route>

              <Route exact path="/how-to">
                <HelpPage />
              </Route>

              <Route path="*">
                <ComingSoon />
              </Route>
            </Switch>
          </div>}
        </BrowserRouter>
      )}

      {!authIsReady && (
        <div className="page-container">
          <Loader text="Wait for login..."/>
        </div>
      )}
        <Overlay/>
        <Popup/>
    </Fragment>
  );
}

export default App
