import { ReactComponent as LogoIcon } from "../../assets/icons/logo_seo_checker_light.svg";
import { NavLink } from 'react-router-dom';

const Logo = props => {
    const {className} = props;
    const classes = `${className}`
  return (
    <NavLink className={classes} exact to="/">
<LogoIcon/>
    </NavLink>
  )
}

export default Logo
