import Loader from '../../../components/Loader';
import ScanSummary from '../../../components/ScanSummary';
import ScanTable from '../../../components/ScanTable';

import { useEffect, useState } from 'react';
import { lessThanOneHourAgo } from '../../../utils/formatDate';
import { useResults } from '../../../hooks/audit/bySitemap/useResults';
import { OPTIONS } from '../../../utils/constants';

import styles from './index.module.css'
import { ReactComponent as BackIcon } from '../../../assets/icons/arrow-back.svg';

const TIMEOUT = OPTIONS.ERROR_LIFETIME;

export default function Results (props) {
  const classes = styles;
  const { domain, setDomain, fetchDomainsData, mode, runScan } = props;

  const { summary, result, scanError } = useResults({
    mode: mode,
    domain: domain,
    report: domain ? domain.reports[0] : null // by now get only last scanned result
  });

  const [ scanButton, setScanButton ] = useState(null);
  const [ error, setError ] = useState(null);

  // Manage scan error
  useEffect(() => {
    if (scanError) {
      setError(`Error: ${scanError}`);

      const timer = setTimeout(() => {
        setDomain(false);
        setError(false);
      }, TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, [scanError]);

  // Check last scan date and show button or start scan
  useEffect(() => {
    if (!summary) {
      return;
    }

    if (mode === 'results_with_scan' && summary.status === 'finished') {
      if (lessThanOneHourAgo(summary.updatedAt)) {
        setScanButton(true);
      } else {
        runScan(domain.id);
      }
    }
  }, [summary, mode, domain]);

  const handleScan = () => {
    runScan(domain.id);
    setScanButton(false);
  }

  const handleGoBack = () => {
    setDomain(false);
    fetchDomainsData();
  }

  const summaryBlock = summary && (
    <ScanSummary data={summary} />
  ) || <Loader text={'Loading...'} />;

  const scanTableBlock = summary?.status === 'finished' && result?.result?.length && (
    <div className="page-block">
      <ScanTable summary={summary} result={result} />
    </div>
  ) || null;

  return (
    <>
      <div className="page-block">
        <div className={classes.head}>
          <button className={`${classes.back} btn-clear`} onClick={handleGoBack}>
            <BackIcon />
            <span>Go back</span>
          </button>
          {domain && (<h2 className={classes.title}>{domain.url}</h2>)}
          <div className={`${classes.statusBlock} ${summary?.status}`}>
            Status: <span className={`${classes.status} status`}>{summary?.status || '-'}</span>
          </div>
          {scanButton && (
            <button onClick={handleScan} className="btn">
              start scan
            </button>
          )}
        </div>
        {!error && summaryBlock}
      </div>

      {scanTableBlock}
      {error && <div className="error">{error}</div>}
    </>
  )
}
