import {useEffect, useState} from 'react';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

import styles from './input.module.css'
import {validURL} from "../../utils/validateUrl";

const InputField = (props) => {
  const { buttonTitle, placeholder, callback, error } = props;
  const [value, setValue] = useState('');
  const [validateError, setValidateError] = useState(false);
  const inputClasses = `${styles.input} ${error ? styles.inputError : ''}`;

  useEffect(() => {
    if (value === '') {
      setValidateError(false);
    }
  }, [value]);

  const resetForm = () => {
    setValue('');
  }

  const submitHandle = (e) => {
    e.preventDefault();

    if (value === '') {
      return;
    }

    if (!validURL(value)) {
      setValidateError(true);

      return;
    }

    setValidateError(false);
    callback(value);

    resetForm();
  }

  return (
    <div className={styles.inputBlock}>
      <form className={styles.wrap} onSubmit={submitHandle}>
        <label className={styles.label}>
          <input
            className={inputClasses}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            placeholder={placeholder}
            type="text"
          />
        </label>
        <button className={`${styles.btn} btn`}>{buttonTitle}</button>

        {validateError && (
          <div className={styles.error}><ErrorIcon/><span>Invalid url</span></div>
        )}
      </form>
    </div>
  )
}

export default InputField
