import styles from './sidebar.module.css';
import {useAppContext} from "../../hooks/useAppContext";

const SidebarFooter = () => {
  const classes = styles;
  const { user } = useAppContext();

  return (
    <div className={classes.footer}>
      <a href={user?.logoutLink}>Logout</a>
    </div>
  )
}

export default SidebarFooter
