import styles from './index.module.css';
import { Fragment } from 'react';
import { usePlanInfoBar } from '../../hooks/usePlanInfoBar';

const PlanInfoBar = props => {
    const {className} = props;
    const titleClasses = `${className} ${styles.title}`;
    const listClasses = `${className} ${styles.list}`;

    const {
        plan,
        title
    } = usePlanInfoBar();

    if (!plan) {
        return false;
    }

    const items = plan.map(item => (
        <li className={styles.item} key={item.key} style={{order: item.order}}>
            <p className={styles.progressTitle}>
                <span>{item.title}:</span>
                <span>{item.valueTitle}</span>
            </p>
            <progress value={item.used} max={item.value}/>
        </li>
    ));

    return (
        <Fragment>
            <span className={titleClasses}>Plan: <b>{title}</b></span>
            <ul className={listClasses}>
                {items}
            </ul>
        </Fragment>
    );
};

export default PlanInfoBar;
