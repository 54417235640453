import styles from './loader.module.css';

const Loader = (props) => {
  const { text } = props;
  const classes = styles;

  return (
    <div className={classes.loader}>{text}</div>
  )
}

export default Loader
