function padTo2Digits(num) {
  return String(num).padStart(2, '0');
}

export const formatDate = (time) => {
  if (!time) {
    return null;
  }

  const date = new Date(time);

  return `${date.getDate()}-${(date.getMonth()+1)}-${date.getFullYear()} ${date.getHours()}:${ padTo2Digits(date.getMinutes())}`;
}

export const lessThanOneHourAgo = (date) => {
  const HOUR = 1000 * 60 * 60;
  const anHourAgo = Date.now() - HOUR;

  return new Date(date).getTime() > anHourAgo;
}