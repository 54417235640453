export const OPTIONS = {
  appName: 'Website SEO Health Check',
  backend: process.env.REACT_APP_BACKEND_URL,
  sso_url: process.env.REACT_APP_SSO_URL,
  ERROR_LIFETIME: 3000
}

export const generateId = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
