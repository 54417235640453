import { useCallback } from 'react';
import { useAppContext } from './useAppContext';

export const useHeader = props => {
    const { Links } = useAppContext();
    const buttonHandler = useCallback(() => {
        window.open(Links.login, '_blank');
    });

    return {
        buttonHandler
    };
};
