import styles from "./pagination.module.css";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { useState } from "react";

const PAGE_SIZE = 30;

const Pagination = props => {
  const classes = styles;
  const { handleGetData } = props;
  const totalCount = props.total;

  const [currentPage, setCurrentPage] = useState(1);

  const calculatedTotals = currentPage * PAGE_SIZE;
  const lastItem =
    calculatedTotals < totalCount ? calculatedTotals : totalCount;
  const firstItem = calculatedTotals - PAGE_SIZE + 1;

  const isFirstPageCurrent = firstItem === 1;
  const isLastPageCurrent = lastItem >= totalCount;

  const nextPage = currentPage + 1;
  const prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;

  const handlePageSwitch = id => {
    handleGetData(id);
    setCurrentPage(id);
  };

  return (
    <>
      {(
        <div className={classes.pagination}>
          <div className={classes.info}>
            <span>
              {firstItem}-{lastItem}
            </span>{" "}
            / <span>{totalCount}</span>
          </div>
          <div className={classes.arrows}>
            <button
              className={`btn-clear ${classes.arrow} ${classes.leftArr} 
                        ${
                          isFirstPageCurrent ? classes.disabled : classes.active
                        }`}
              title={`Open page ${prevPage}`}
              onClick={() => handlePageSwitch(prevPage)}
            >
              <ArrowIcon />
            </button>
            <button
              className={`btn-clear ${classes.arrow} ${classes.rightArr} 
                        ${
                          isLastPageCurrent ? classes.disabled : classes.active
                        }`}
              title={`Open page ${nextPage}`}
              onClick={() => handlePageSwitch(nextPage)}
            >
              <ArrowIcon />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
