import styles from './scanTable.module.css';
import {ReactComponent as BackIcon} from "../../assets/icons/arrow-back.svg";
import Pagination from "../Pagination";

const Details = props => {
  const classes = styles;
  const { details, setDetails, handleSeeDetails } = props;
  const { element, urlData } = details;

  const getSeoErrors = (page = 1) => {
    handleSeeDetails(element, page);
  }

  return (
    <>
      <button className={`${classes.back} btn-clear`} onClick={() => setDetails(false)}>
        <BackIcon />
        <span>Back to Results</span>
      </button>
      {element && <div className={classes.detailsTitle}>{element.title}</div>}
      <ul className={classes.details}>
        {urlData && urlData.map(element => (
          <li key={element.id}>
            <a href={element.url} target="_blank" rel="noreferrer">{element.url}</a>
          </li>
        ))}
      </ul>

      <Pagination items={urlData} total={element.quantity} handleGetData={getSeoErrors} />
    </>
  );
};

export default Details;
