import PrimaryButton from "./Primary"
import SecondaryButton from "./Secondary"
import IconButton from "./Icon"
import React, { Fragment, useCallback } from "react"

const Button = props => {
  const { viewType, onClick, anchor } = props
  const type = viewType.toLowerCase()
  const scrollToAnchor = useCallback(() => {
  const target = document.getElementById(anchor.toLowerCase())
    if (target) {
      target.scrollIntoView({ behavior: "smooth", inline: "nearest" })
    }
  }, [anchor])

  const clickHandler = anchor ? scrollToAnchor : onClick || null

  return (
    <Fragment>
      {type === "primary" && (
        <PrimaryButton clickHandler={clickHandler} {...props} />
      )}

      {type === "secondary" && (
        <SecondaryButton clickHandler={clickHandler} {...props} />
      )}

      {type === "icon" && (
          <IconButton clickHandler={clickHandler} {...props} />
      )}
    </Fragment>
  )
}

export default Button
